import { Link, Outlet } from "react-router-dom";
import NavBar from "../nav/NavBarView";
import HeaderView from "../header/HeaderView";
import Home from "./Home";
import FooterView from "../footers/FooterView";

export default function Layout() {

  return (
    <>
      <HeaderView />
      
      <Outlet />

      <footer className="pt-5">
        <FooterView />
      </footer>
      
    </>
  )
}