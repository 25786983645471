


export default function WhyUsView() {

  return <>
    <div className="container lh-lg">
      <h5 className="text-danger text-center fw-bold">Why Choose Druva Reqroots?</h5>
      <div>
        <p><b>Industry Expertise:</b> Our team of seasoned recruiters have deep knowledge and understanding of various industries, ensuring that we provide you with the most qualified candidates for your specific needs.</p>
        <p><b>Personalized Approach:</b> We understand that every company is unique. That's why we tailor our services to fit your specific needs, ensuring we find the right candidates who align with your company culture and values.</p>
        <p><b>Speed & Efficiency:</b> We know that your time is valuable. We strive to make the hiring process as efficient as possible, without compromising the quality of candidates.</p>
        <p><b>Passion for People:</b> At the heart of our business is a genuine passion for people. We're committed to building lasting relationships with both clients and candidates to ensure long-term success.</p>
      </div>
    </div>
  </>;
}