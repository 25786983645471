import React, { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import OurMissionView from '../our-mission/OurMissionView';

export default function HomeView() {

  const carouselStyle = {
    maxHeight: '600px'
  };

  return (
    <>
      <Carousel indicators={false}>
        <Carousel.Item style={carouselStyle}>
          <img className="d-block w-100"
            src="https://druvareqroots.com/wbc/images/education.jpg" alt='education' />
        </Carousel.Item>
        <Carousel.Item style={carouselStyle}>
          <img className="d-block w-100"
            src="https://druvareqroots.com/wbc/images/services-ites.jpg" alt='services-ites' />
        </Carousel.Item>
        <Carousel.Item style={carouselStyle}>
          <img className="d-block w-100"
            src="https://druvareqroots.com/wbc/images/construction.jpg" alt='construction' />
        </Carousel.Item>
      </Carousel>
      <div className='container pt-2'>
        <p>Druva Reqroots is the best recruitment firm in India. It has been a well-known talent acquisition firm that caters to the human capital for the IT, ITeS, Garments, Fireworks, Manufacturing, Education, Real Estate, Construction, Resorts, Healthcare, Banking and Financial Services etc.</p>
        <p>We believe in better staffing that builds a better managerial organization. Backed by this ideology, Druva Reqroots helps job seekers with a suitable job opportunity at various firms that match their candidature. We have a strong database and an updated market intelligence who headhunts candidates legible for the profile and then shortlist them for the further recruitment procedure.</p>
        <p>Druva Reqroots has been consistent with its recruitment and hiring practices since the time it was commenced. We have a team of professionals who assess candidates that match the client's business requirements. These topmost professionals, having the best knowledge from the industry, gives solutions to our client and transforms the employment procedural in their company. We always stay focused on fulfilling the client's necessities and work with them closely to provide the best recruitment service.</p>
      </div>
    </>
  );

}
