import AddressView from "../address/AddressView";
import ContactUsView from "../contact-us/ContactUsView";
import SimpleMap from "../map/SimpleMap";

export default function ContactUs() {

  return (
    <>
      <ContactUsView />
      <div className="clearfix mt-2">
        <div className="lh-lg">
          <AddressView />
          <div className="container">
            <img src="https://druvareqroots.com/wbc/images/map-locations.png" className="float-start mw-100 px-2"
              alt="..." />
          </div>
        </div>
      </div>
    </>
  )
}