import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

export default function FooterView() {

  const [footerData, setFooterData] = React.useState({})

  React.useEffect(() => {
    fetch('/data/footer-data.json').then((res) => res.json()).then((data) => {
      setFooterData(data)
    })
  }, [])

  const titleElement = (title) => {
    return <h5 className='fw-bold'>{title}</h5>
  }

  return (
    <>
      <div className="bg-dark text-white">
        <div className='container'>
          {
            footerData && footerData.aboutUs &&
            <div className='row pt-5'>
              <div className="col">
                {titleElement(footerData.aboutUs.title)}
                <p className='lh-base pe-2'>{footerData.aboutUs.description}</p>
              </div>
              <div className="col">
                {titleElement(footerData.quickWebLinks.title)}
                <ul className='nav flex-column'>
                  {footerData.quickWebLinks.links.map(item => (
                    <li className='nav-item mt-2'>
                      <a className="nav-link p-0 text-white" href={item.path}>
                        <i className='fa fa-angle-double-right'></i>
                        <span className='ms-2'>{item.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col">
                {titleElement(footerData.contactUs.title)}
                <ul className='nav flex-column'>
                  {footerData.contactUs.list.map(item => (
                    <li className='nav-item mt-2'>
                      <i className={"fa " + item.faIconClass} style={{ fontSize: '14px' }}></i>
                      <span className='ms-2'>{item.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          }
        </div>
        <div className='py-4 border-top border-dark-subtle text-center'>
          <p>Copyright © 2023 Druva Reqroots. All Right Reserved.</p>
        </div>
      </div>
    </>
  );
}
