import JobView from "../jobs/JobView";

export default function Job() {

  return (
    <div className="pt-5">
      <h5 className="text-center text-danger fw-bold pb-2">JOBS</h5>
      <JobView />
    </div>
  )
}