import React from "react";
import JobVacancyCard from "../cards/JobVacancyCard";

const cardContainer = document.getElementById("our-services");

export default function OurServiceView() {

    const [ourServiceData, setOurServiceData] = React.useState({})

    React.useEffect(() => {
        fetch('/data/our-services-data.json').then((res) => res.json()).then((data) => {
            setOurServiceData(data)
        })
    }, [])

    const serviceCard = (service) => {
        return <div className='card m-2' style={{ width: '18rem' }}>
            <img src={service.imageUrl} className="card-image-top" alt={service.title} />
            <div className="card-body">
                <h5 className="card-title">{service.title}</h5>
                {service.description && <p className="card-text">{service.description}</p> }
            </div>
        </div>;
    }


    return (
        <div className="container">
            <div>
                <h5 className="text-center fw-bold text-danger pt-5">OUR SERVICES</h5>
                <div>
                    <p>We offer a comprehensive range of recruitment services, including:</p>
                    <p><b>Executive Search: </b>We help you locate and attract the top executive talent in your industry.</p>
                    <p><b>Contract Staffing: </b>For companies seeking flexibility, we provide top-quality contract workers to meet your unique needs.</p>
                    <p><b>Permanent Placement: </b>We find and place skilled professionals who are ready to make a long-term commitment to your company.</p>
                    <p><b>Temporary Staffing: </b>Whether you need to cover a leave of absence, a seasonal peak, or a special project, we can provide temporary staff on short notice.</p>
                </div>
            </div>
            <div className="d-flex flex-wrap align-items-center">
                {
                    ourServiceData && ourServiceData.content && ourServiceData.content.map(service => (
                        serviceCard(service)
                    ))
                }
            </div>
        </div>
    )

    /*

    createHeaderContent() {
        const content = document.createElement("div");
        content.className = "container";

        const html =
            "<h6 className='text-danger  text-center'>SERVICES</h6> \
            <h5 className='text-center'>We offer a comprehensive range of recruitment services.</h5> \
            <p></p> \
            <p> <span className='fw-bold'>Executive Search: </span></p><p>We help you locate and attract the top executive talent in your industry.</p> \
            <p> <span className='fw-bold'>Contract Staffing: </span></p><p>For companies seeking flexibility, we provide top-quality contract workers to meet your unique needs.</p> \
            <p> <span className='fw-bold'>Permanent Placement: </span></p><p>We find and place skilled professionals who are ready to make a long-term commitment to your company.</p> \
            <p> <span className='fw-bold'>Temporary Staffing: </span></p><p>Whether you need to cover a leave of absence, a seasonal peak, or a special project, we can provide temporary staff on short notice.</p> \
        ";

        content.innerHTML = html;
        return content;
    }

    createCards() {
        const cards = document.createElement("div");
        cards.className = "container d-flex flex-wrap align-items-center";

        const data = ourServicesData.content;
        for (let i = 0; i < data.length; i++) {

            const imgSrc = data[i].imageUrl;
            const title = data[i].title;
            const text = data[i].description;

            const card = this.createCard(imgSrc, title, text);

            cards.appendChild(card);
        }

        return cards;
    }

    createCard(imgSrc, title, text) {
        const card = document.createElement("div");

        card.className = 'card m-2';
        card.style.width = "18rem";

        card.innerHTML = "\
            <img src='" + imgSrc + "' className='card-img-top' > \
            <div className='card-body'> \
                <h5 className='card-title'>" + title + "</h5> \
                <p className='card-text'>" + text + "</p> \
            </div> ";

        return card;
    }

    page() {
        const contentHeader = this.createHeaderContent();
        const cards = this.createCards();

        cardContainer.appendChild(contentHeader);
        cardContainer.appendChild(cards);
    }
    */
}


