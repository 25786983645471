import HomeView from "../home/HomeView";
import OurMissionView from "../our-mission/OurMissionView";
import WhyUsView from "../why-us/WhyUsView";
import AboutUs from "./AboutUs";
import Job from "./Job";
import OurService from "./OurService";

export default function Home() {

  return (
    <>
      <section>
        <section>
          <HomeView />
        </section>
        <section className="py-2">
          <AboutUs />
        </section>
        <section className="py-2">
          <OurMissionView />
        </section>
        <section className="py-2">
          <WhyUsView />
        </section>
        <section className="py-2">
          <OurService />
        </section>
        <section className="py-2">
          <Job />
        </section>
      </section>
    </>
  )
}