


export default function OurMissionView() {

  return <>
    <div className="container lh-lg">
      <h5 className="text-danger text-center fw-bold">Our Mission</h5>
      <p>Our mission is to help companies achieve their full potential by providing them with the best-suited talent for their needs. We believe that every successful business begins and ends with exceptional people.</p>
    </div>
  </>;

}