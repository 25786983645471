import React from "react"
import JobVacancyCard from "../cards/JobVacancyCard"

export default function JobView() {

    const [jobs, setJobs] = React.useState([{}])

    React.useEffect(() => {
        fetch('/data/jobs.json').then((res) => res.json()).then((data) => {
            setJobs(data)
        })
    }, [])

    return (
        <div className="container d-flex flex-wrap align-items-center">
            {jobs && jobs.map(job => (
                <JobVacancyCard job={job} />
            ))}
        </div>
    );
}