import React from "react";
import { Link, useLocation } from "react-router-dom";


export default function NavBarView(props) {

    const [menu, setMenu] = React.useState({})

    React.useEffect(() => {
        fetch('/data/menu.json').then((res) => res.json()).then((data) => {
            setMenu(data)
        })
    }, [])

    const location = useLocation();

    const { pathname } = location;

    console.log("pathname: " + pathname);

    // const { location } = this.props;

    // const homeClass = location.pathname === "/" ? "active" : "";
    // const aboutClass = location.pathname.match(/^\/about/) ? "active" : "";
    // const contactClass = location.pathname.match(/^\/contact/) ? "active" : "";


    // var isActive = this.context && this.context.router.route.location.pathname === this.props.to;
    // var className = isActive ? 'active' : '';

    // console("className: " + className);

    return (<>
        <nav className="navbar navbar-dark bg-dark navbar-expand-md">
            <div className="container-fluid">
                <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse" id="navbar">
                    <ul className="navbar-nav nav-underline" data-bs-theme="dark">                        
                        {
                            menu && menu.content &&
                            menu.content.map(item => (
                                <li className="nav-item"><Link className={ "nav-link" + (item.path == pathname ? " active" : "") } to={item.path}>{item.title}</Link></li>
                            ))
                        }
                    </ul>
                </div>
                <ul className="social d-flex">
                    <li className="px-2">
                        <a href="/" title="Facebook" target="_blank">
                            <i className="fa fa-facebook"></i></a>
                    </li>
                    <li className="px-2"><a href="/" title="Twitter" target="_blank"><i className="fa fa-twitter"></i></a></li>
                    <li className="px-2" >
                        <a href="https://web.whatsapp.com/send?phone=+918072307460&amp;text=Enquiry For Job Consultancy in Madurai" id="wh" title="WhatsApp" target="_blank">
                            <i className="fa fa-whatsapp"></i>
                        </a>
                    </li >
                    <li className="px-2"><a href="/" title="Youtube" target="_blank"><i className="fa fa-youtube"></i></a></li>
                </ul >
            </div >
        </nav >
    </>
    );
}