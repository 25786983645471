
export default function JobVacancyCard({ job }) {

    return (
        <div className='card m-2' style={{ width: '24rem' }}>
            <div className="card-body">
                <h5 className="card-title text-primary">{job.title}</h5>
                <p className="card-text lh-lg">
                    {job.infos && job.infos.map(item => (
                        <span><b>{item.displayName}:</b> {item.value}<br/></span> 
                    ))}
                </p>
            </div>
        </div>
    );
}