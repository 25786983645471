

export default function TobBarView() {
    return (
        <div id="top-header" className="container-fluid py-1" >
            <div className="d-flex align-items-center">
                <div className="p-2 flex-grow-1">
                    <a href="/">
                        <img className="mw-100"
                            src="https://druvareqroots.com/wbc/images/home-logo.jpg"
                            height="60px" />
                    </a>
                </div>

                <div className="p-2">
                    <div className="d-flex align-items-center">
                        <a type="button" className="btn btn-danger btn-md btn-block rounded-circle" href="tel:+918072307460">
                            <i className="fa fa-volume-control-phone"></i>
                        </a>
                        <div className="ms-3 hideMob">
                            <p>+918072307460</p>
                        </div>
                    </div>
                </div>
                <div className="p-2">
                    <div className="d-flex align-items-center">
                        <a href="mailto:hr@druvareqroots.com" className="align-middle btn btn-danger btn-md btn-block rounded-circle">
                            <i className="fa fa-envelope-o"></i>
                        </a>
                        <div className="ms-3 hideMob">
                            <p>hr@druvareqroots.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}