import NavBarView from "../nav/NavBarView";
import TobBarView from "../top-bar/TobBarView";


export default function HeaderView() {

  return (
    <>
      <TobBarView />
      <NavBarView />
    </>
  )
}