import React from "react"



export default function ContactUsView() {

  const [contactUsData, setContactUsData] = React.useState({})

  React.useEffect(() => {
    fetch('/data/contactus-data.json').then((res) => res.json()).then((data) => {
      setContactUsData(data)
    })
  }, [])

  const listItems = (items) => {
    return <div className="row py-2">
      {items.map(item => (
        < div className="col" >
          <div className="float-start"><i className={"fa icon-inside " + item.faIconClass}></i></div>
          <p className="ms-4">
            <b>{item.displayName}</b><br />
            {item.link ? (<a href={item.link} className="text-decoration-none" target="_blank">{item.value}</a>) : item.value}
          </p>
        </div>
      ))
      }
    </div >;
  }

  const contactList = (items) => {

    const colsPerRow = 2;
    let rowItems = [];
    let rowItemElements = [];

    for (var i = 0; i < items.length; i++) {
      rowItems.push(items[i]);
      if (rowItems.length == colsPerRow) {
        rowItemElements.push(listItems(rowItems));
        rowItems = [];
      }
    }

    if (rowItems.length > 0) {
      rowItemElements.push(listItems(rowItems));
      rowItems = [];
    }



    return rowItemElements;
    //     <div className="row">
    //       {items.map(item => (
    //         rowItems.push(item)
    //         if () {

    //         }
    //       ))
    // }
    //     </div >;
  }

  return (
    <>
      <div className="container">
        {
          contactUsData && contactUsData.title && <div>
            <h5 className="fw-bold pt-5">{contactUsData.title}</h5>
            {contactList(contactUsData.list)}
          </div>
        }

      </div>
    </>
  )
}