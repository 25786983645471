import OurServiceView from "../our-services/OurServiceView";

export default function OurService() {

  return (
    <>
      <section id="our-services" className="container">
        <OurServiceView />
      </section>
    </>
  )
}