import React from "react"



export default function AddressView() {

  const [data, setData] = React.useState({})

  React.useEffect(() => {
    fetch('/data/address-data.json').then((res) => res.json()).then((data) => {
      setData(data)
    })
  }, [])

  // {
  //   "name": "Madurai",
  //   "address1": "Airpot-Mattuthavani Ring Rd",
  //   "city": "Madurai",
  //   "state": "Tamil Nadu",
  //   "pincode": "625020"
  // }

  const listItems = (items) => {
    return <div className="row py-2">
      {items.map(item => (
        < div className="col" >
          <div className="float-start"><i className={"fa icon-inside " + data.faIconClass}></i></div>
          <p className="ms-4">
            <b>{item.name}</b> <br />
            {item.address1} <br />
            {item.city}, {item.state} - {item.pincode} <br />
          </p>
        </div>
      ))
      }
    </div >;
  }

  const contactList = (items) => {

    const colsPerRow = 2;
    let rowItems = [];
    let rowItemElements = [];

    for (var i = 0; i < items.length; i++) {
      rowItems.push(items[i]);
      if (rowItems.length == colsPerRow) {
        rowItemElements.push(listItems(rowItems));
        rowItems = [];
      }
    }

    if (rowItems.length > 0) {
      rowItemElements.push(listItems(rowItems));
      rowItems = [];
    }



    return rowItemElements;
    //     <div className="row">
    //       {items.map(item => (
    //         rowItems.push(item)
    //         if () {

    //         }
    //       ))
    // }
    //     </div >;
  }

  return (
    <>
      <div className="container">
        {
          data && data.name && <div>
            <h5 className="fw-bold pt-5">{data.displayName}</h5>
            {contactList(data.addresses)}
          </div>
        }

      </div>
    </>
  )
}