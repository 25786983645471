
export default function AboutUs() {

  return (
    <>
      <div id="about-us-section" className="container clearfix mt-2">
        <div className="lh-lg">
          <img src="https://druvareqroots.com/wbc/images/about-us.jpg" className="float-start mw-100 px-2"
            alt="..." />
          <div className="container">
            <h5 className="text-danger text-start fw-bold">ABOUT US</h5>
            <h4>Welcome to Druva Reqroots - Your Partner in Talent Acquisition</h4>
            <p>
              At Druva Reqroots, we understand the profound impact that great talent can have on a business. As a leading
              recruitment agency, we specialize in connecting exceptional professionals with outstanding opportunities in
              companies across a wide range of industries like IT, ITeS, Garments, Fireworks, Manufacturing, Education, Real
              Estate, Construction, Resorts, Healthcare, Banking and Financial Services etc.
            </p>
          </div>
        </div>        
      </div>
    </>
  );

}